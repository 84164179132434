/**
 * Created by osirvent on 24/04/2017.
 */
angular
    .module('annexaApp')
    .component('annexaDocumentTitle', {
        templateUrl: './components/doc/annexa-document-title/annexa-document-title.html',
        require: {
            documentComponent: '^^annexaDocument'
        },
        controller: ['DccumentsFactory', function(DccumentsFactory) {
            var vm = this;
            
            this.$onInit = function() {
            	if(DccumentsFactory.statuses && DccumentsFactory.statuses.length > 0 && 
            			vm.documentComponent && vm.documentComponent.document && vm.documentComponent.document.docStatus){
            		vm.state = $linq(DccumentsFactory.statuses).singleOrDefault(undefined, "x => x.id == '"+vm.documentComponent.document.docStatus+"'");
           		}
            }

        }]
    })
