/**
 * Created by osirvent on 25/04/2017.
 */
angular
    .module('annexaApp')
    .component('annexaDocumentLabelValue', {
        templateUrl: './components/doc/annexa-document-label-value/annexa-document-label-value.html',
        controller: [function () {
            var vm = this;
        }],
        bindings: {
            label: '@',
            value: '='
        }
    })