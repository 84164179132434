/**
 * Created by apop on 06/09/2018.
 */
angular
    .module('annexaApp')
    .component('annexaDocumentEmgdeConfig', {
        templateUrl: './components/doc/annexa-document-emgde-config/annexa-document-emgde-config.html',
        controller: ['Language', '$rootScope', 'GlobalDataFactory', '$filter', 'globalModals', 'AnnexaFormlyFactory', 'DialogsFactory', 'AnnexaPermissionsFactory', function (Language, $rootScope, GlobalDataFactory, $filter, globalModals, AnnexaFormlyFactory, DialogsFactory, AnnexaPermissionsFactory) {
            var vm = this;
            vm.languageColumn = Language.getActiveColumn();
			vm.classEmgdeValues = 'collapse';
			vm.getLiteral = function(type, value){
				var literal = '';
				if(type){
					if(type == 'emgdeSecurityLevel'){
						if(value){
							var literalAux = $linq(GlobalDataFactory.emgdeLists.securityLevels).firstOrDefault(undefined,"x => x.id == '"+value+"'");
							if(literalAux && literalAux.description){
								literal = $filter('translate')(literalAux.description);
							}
						}
					} else if(type == 'emgdeAccesType'){
						if(value){
							var literalAux = $linq(GlobalDataFactory.emgdeLists.accesTypes).firstOrDefault(undefined,"x => x.id == '"+value+"'");
							if(literalAux && literalAux.description){
								literal = $filter('translate')(literalAux.description);
							}
						}
					} else if(type == 'emgdeLimitCause'){
						if(value){
							var literalAux = $linq(GlobalDataFactory.emgdeLists.limitCauses).firstOrDefault(undefined,"x => x.id == '"+value+"'");
							if(literalAux && literalAux.description){
								literal = $filter('translate')(literalAux.description);
							}
						}
					} else if(type == 'emgdeValueType'){
						if(value){
							var literalAux = $linq(GlobalDataFactory.emgdeLists.valueTypes).firstOrDefault(undefined,"x => x.id == '"+value+"'");
							if(literalAux && literalAux.description){
								literal = $filter('translate')(literalAux.description);
							}
						}
					} else if(type == 'terms'){
						if(value){
							literal = value;
						}
					} else if(type == 'emgdeSecundaryValue'){
						if(value){
							var literalAux = $linq(GlobalDataFactory.emgdeLists.secundaryValues).firstOrDefault(undefined,"x => x.id == '"+value+"'");
							if(literalAux && literalAux.description){
								literal = $filter('translate')(literalAux.description);
							}
						}
					} else if(type == 'emgdeEssentialDocument'){
						if(value){
							literal = $filter('translate')('DIALOGS_YES')
						}else{
							literal = $filter('translate')('DIALOGS_NO')
						}
					} else if(type == 'emgdeTransferArchive'){
						if(value){
							var literalAux = $linq(GlobalDataFactory.emgdeLists.archiveFases).firstOrDefault(undefined,"x => x.id == '"+value+"'");
							if(literalAux && literalAux.description){
								literal = $filter('translate')(literalAux.description);
							}
						}
					} else if(type == 'emgdeOpinionType'){
						if(value){
							var literalAux = $linq(GlobalDataFactory.emgdeLists.opinionTypes).firstOrDefault(undefined,"x => x.id == '"+value+"'");
							if(literalAux && literalAux.description){
								literal = $filter('translate')(literalAux.description);
							}
						}
					}
				}	
				return literal;
			}
			
			vm.updateEMGDE = function(fieldName, literal){
				if(fieldName){
					var fields = [];
	            	var data = {row: true,colClass: ' col-sm-12',labelClass: 'label-strong'};
	                var modal = angular.copy(globalModals.emgdeModal);
	                modal.annexaFormly = new AnnexaFormly();
	                modal.annexaFormly.addGroup('modal_body','modal-body p-lg',fields);
	                modal.annexaFormly.model = {};
	                modal.annexaFormly.model.modal_body = {};
					modal.annexaFormly.model.modal_body[fieldName] = angular.copy(vm.object[fieldName]);
					modal.annexaFormly.options = {};
	                modal.annexaFormly.options.formState = {readOnly: false};
	                modal.alerts = [];
					if(fieldName == 'emgdeSecurityLevel'){
						var field = modal.annexaFormly.createField(fieldName, 'annexaSelectRow', 'col-sm-12', new AnnexaFormlyFieldSelectTemplateOptions('global.documents.EMGDE.'+literal,'id','description',GlobalDataFactory.emgdeLists.securityLevels,true),data);                
						fields.push(field);
					}else if(fieldName == 'emgdeAccesType'){
						var field = modal.annexaFormly.createField(fieldName, 'annexaSelectRow', 'col-sm-12', new AnnexaFormlyFieldSelectTemplateOptions('global.documents.EMGDE.'+literal,'id','description',GlobalDataFactory.emgdeLists.accesTypes,true),data);                
						fields.push(field);
					}else if(fieldName == 'emgdeLimitCause'){
						var field = modal.annexaFormly.createField(fieldName, 'annexaSelectRow', 'col-sm-12', new AnnexaFormlyFieldSelectTemplateOptions('global.documents.EMGDE.'+literal,'id','description',GlobalDataFactory.emgdeLists.limitCauses,true),data);
						fields.push(field);
					}else if(fieldName == 'emgdeValueType'){
						var field = modal.annexaFormly.createField(fieldName, 'annexaSelectRow', 'col-sm-12', new AnnexaFormlyFieldSelectTemplateOptions('global.documents.EMGDE.'+literal,'id','description',GlobalDataFactory.emgdeLists.valueTypes,true),data);                
						fields.push(field);
					}else if(fieldName == 'emgdeRatingTerms' || fieldName == 'emgdeTransferTerms' || fieldName == 'emgdeOpinionActionTerms'){
						var field = modal.annexaFormly.createField(fieldName,'annexaInputRow','col-sm-12',new AnnexaFormlyFieldTemplateOptions('number','global.documents.EMGDE.'+literal,true,true),data);                
						fields.push(field);
					}else if(fieldName == 'emgdeSecundaryValue'){
						var field = modal.annexaFormly.createField(fieldName, 'annexaSelectRow', 'col-sm-12', new AnnexaFormlyFieldSelectTemplateOptions('global.documents.EMGDE.'+literal,'id','description',GlobalDataFactory.emgdeLists.secundaryValues,true),data);                
						if(field && field.templateOptions){
							field.templateOptions.labelPropAux = 'descriptionAux';
						}
						fields.push(field);
					}else if(fieldName == 'emgdeOpinionType'){
						var field = modal.annexaFormly.createField(fieldName, 'annexaSelectRow', 'col-sm-12', new AnnexaFormlyFieldSelectTemplateOptions('global.documents.EMGDE.'+literal,'id','description',GlobalDataFactory.emgdeLists.opinionTypes,true),data);                
		                fields.push(field);
					}else if(fieldName == 'emgdeTransferArchive'){
						var field = modal.annexaFormly.createField(fieldName, 'annexaSelectRow', 'col-sm-12', new AnnexaFormlyFieldSelectTemplateOptions('global.documents.EMGDE.'+literal,'id','description',GlobalDataFactory.emgdeLists.archiveFases,true),data);                
		                fields.push(field);
					}else if(fieldName == 'emgdeEssentialDocument'){
						var field = modal.annexaFormly.createField(fieldName, 'annexaSelectRow', 'col-sm-12', new AnnexaFormlyFieldSelectTemplateOptions('global.documents.EMGDE.'+literal,'id','description',GlobalDataFactory.emgdeLists.essentialDocuments,true),data);                
						fields.push(field);
					}else{
						var field = modal.annexaFormly.createField(fieldName,'annexaInputRow','col-sm-12',new AnnexaFormlyFieldTemplateOptions('text','global.documents.EMGDE.'+literal,true,true,undefined,255),data);                
						fields.push(field);
					}
					var saveEMGDEDataConfirm = function(){
						var model = modal.annexaFormly.model.modal_body;
						if(model){
							if(vm.update){
								if(vm.updateWithoutDialog){
									vm.update(model[fieldName], fieldName, modal);
								}else{
									DialogsFactory.confirm("global.documents.EMGDE.updateEMGDE", "global.documents.EMGDE.updateEMGDEConfirm").then(function (dataAux) {
										vm.update(model[fieldName], fieldName, modal);
				                    }).catch(function (data) {
				                    	//Empty
				                    });
								}
							}else{
								vm.object[fieldName] = model[fieldName];
								modal.close();
							}
						}      	
					}
					AnnexaFormlyFactory.showModal("modalAddModifyDate", modal, saveEMGDEDataConfirm, false);
				}
			}
					
            this.$onInit = function () {
				if(vm.openContent){
					vm.classEmgdeValues = 'collapse in';
				}	
            }
        }],
        bindings: {
            object: '=',
			update: '=?',
			updateWithoutDialog:'=?',
			openContent: '=?'
        }
    })
	
	