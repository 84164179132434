angular
    .module('annexaApp')
    .component('annexaDocumentViewModesFileIcon', {
        templateUrl: './components/doc/annexa-document-view-modes-file-icon/annexa-document-view-modes-file-icon.html',
        controller:[function () {
            var vm = this;

            vm.getIcon = function () {
                switch (vm.fileType) {
                    case 'folder':
                        return "fa-folder";
                    case 'excel':
                        return "fa-file-excel-o";
                    case 'pdf':
                        return 'fa-file-pdf-o';
                    case 'word':
                        return "fa-file-word-o";
                    case 'image':
                        return "fa-file-image-o";
                    case 'text':
                        return "fa-file-text-o";
                    case 'audio':
                        return "fa-file-audio-o";
                    case 'powerpoint':
                        return "fa-file-powerpoint-o";
                    case 'video':
                        return "fa-file-video-o";
                }

                return 'fa-file-o';
            }

            vm.getTitle = function () {
                switch (vm.fileType) {
                    case 'folder':
                        return 'global.fileTypes.folder';
                    case 'excel':
                        return 'global.fileTypes.excel';
                    case 'pdf':
                        return 'global.fileTypes.pdf';
                    case 'word':
                        return 'global.fileTypes.word';
                    case 'image':
                        return 'global.fileTypes.image';
                    case 'text':
                        return 'global.fileTypes.text';
                    case 'audio':
                        return 'global.fileTypes.audio';
                    case 'powerpoint':
                        return 'global.fileTypes.powerpoint';
                    case 'video':
                        return 'global.fileTypes.video';
                }

                return 'global.fileTypes.unknown';
            }
        }],
        bindings: {
            fileType: '='
        }
    })