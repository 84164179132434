angular
    .module('annexaApp')
    .component('annexaPublishStatusesEvidences', {
        templateUrl: './components/doc/annexa-publish-statuses-evidences/annexa-publish-statuses-evidences.html',
        controller: ['$rootScope', '$filter', '$scope', 'CommonService', '$http', function ($rootScope, $filter, $scope, CommonService, $http) {
            var vm = this;

            vm.viewEvidence = function (evidence) {
                $http({
                    url: './api/plugin/publish/evidence/' + CommonService.fixRepoIdForSendRest(evidence),
                    method: 'GET',
                }).then(function(data) {
                	if(data && data.data && data.data.url){
                		window.open(data.data.url, '_blank');
                	}
                }).catch(function(error) {
                	console.error(error);
                });
            }
        }],
        bindings: {
            statuses: '='
        }
    })