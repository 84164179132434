/**
 * Created by apop on 07/09/2018.
 */
angular
    .module('annexaApp')
    .component('annexaDocumentLabelEniValue', {
        templateUrl: './components/doc/annexa-document-label-eni-value/annexa-document-label-eni-value.html',
        controller: [function () {
            var vm = this;
        }],
        bindings: {
            label: '@',
            value: '='
        }
    })