/**
 * Created by osirvent on 25/04/2017.
 */
angular
    .module('annexaApp')
    .component('annexaDocumentInformationBox', {
        templateUrl: './components/doc/annexa-document-information-box/annexa-document-information-box.html',
        require: {
            documentComponent: '^^annexaDocument'
        },
        controller: ['Language', 'DccumentsFactory', 'GlobalDataFactory', 'AnnexaFormlyFactory', 'DccumentsFactory', 'DialogsFactory', 'CustomFieldFactory', '$state', '$filter', function (Language, DccumentsFactory, GlobalDataFactory, AnnexaFormlyFactory, DccumentsFactory, DialogsFactory, CustomFieldFactory, $state, $filter) {
            var vm = this;
			vm.canChangeDocumentType = false;
            vm.languageColumn = Language.getActiveColumn();
			vm.docTypes = [];
            vm.updateCustomField = function (field) {
                var model = {
                    document: { id: vm.documentComponent.document.id },
                    customField: { id: field.customField.id },
                    required: field.required,
                    viewOrder: field.viewOrder,
                    value: field.value,
                    id: field.id
                };

                DccumentsFactory.updateDocumentCustomField(model)
                    .then(function(data) {
                        //Empty
                    }).catch(function(error) {
                        //Empty
                });
            };

			vm.openModalUpdateDocumentType = function(){
                var saveModalUpdateThird = function(data){
			        if (data.annexaFormly.form.$valid) {
		                var model = data.annexaFormly.model.modal_body;
						var moduleDoc = 'DOC';
		
		                var document = {
							id:vm.documentComponent.document.id,
		                    type: {id: model.typeSelect}
		                };
						var cf = CustomFieldFactory.getModelValues(model);
						document.customFields = [];
		                var documentType = $linq(GlobalDataFactory.documentTypes).singleOrDefault(undefined, "x => x.id == " + document.type.id);
		                if(documentType && cf && cf.length > 0) {
							_.forEach(documentType.customFields, function(docTypeField) {
		                        var docCustomField = {
		                            customField: { id: docTypeField.customField.id },
		                            required: docTypeField.required,
		                            viewOrder: docTypeField.viewOrder,
		                            value: docTypeField.value,
		                            noEditable: docTypeField.noEditable
		                        };
								var cfValue = $linq(cf).firstOrDefault(undefined, "x => x.id == 'cf_" + documentType.id  + "_" + docTypeField.customField.id + "'");
								if(cfValue) {
									if(docTypeField.customField.frontendType == 'CHECKBOX'){
		                                var custom_field_selected = $linq(cf).where(function(x){
		                                    if(x.id.startsWith(cfValue.id+"_")){return true}else{return false}}
		                                ).toArray();
		                                if(custom_field_selected && custom_field_selected.length > 0){
		                                    docCustomField.value = '[';
		                                    _.forEach(custom_field_selected, function (item, key) {
		                                        if(item.value) {
		                                            if(docTypeField.customField && docTypeField.customField.listValues && docTypeField.customField.listValues.length > 0) {
		                                                var custom_field_value_selected = $linq(docTypeField.customField.listValues).where(function(x){
		                                                    if(item.id.endsWith("_"+x.value)){return true}else{return false}}
		                                                ).toArray();
		                                                if(custom_field_value_selected && custom_field_value_selected.length > 0){
		                                                    docCustomField.value += ((docCustomField.value == '[') ?custom_field_value_selected[0].value : ',' + custom_field_value_selected[0].value);
		                                                }
		                                            }
		                                        }
		                                    });
		                                    docCustomField.value += ']';
		                                }
		                            }else if(Array.isArray(cfValue.value)) {
		                                if(docTypeField.customField.frontendType == 'MULTIPLESELECT') {
		                                    docCustomField.value = '[';
		
		                                    _.forEach(cfValue.value, function (item) {
		                                        docCustomField.value += ((docCustomField.value == '[') ? item : ',' + item);
		                                    });
		
		                                    docCustomField.value += ']';
		                                } else {
		                                    docCustomField.value = cfValue.value[0];
		                                }
		                            } else {
		                                if(cfValue.value instanceof Date) {
		                                    docCustomField.value = $filter('date')(cfValue.value,'yyyy-MM-ddTHH:mm:ss');
		                                } else {
		                                	if (cfValue && cfValue.value && cfValue.value.toString().includes("\\")) {
		                                		cfValue.value = ((cfValue.value.replaceAll)?cfValue.value.replaceAll('\\','\\\\'):cfValue.value.replace(/\\/g,'\\\\')); 
		                                	}
		                                    docCustomField.value = cfValue.value;
		                                }
		                            }
				                }
								document.customFields.push(docCustomField);
							});
						}
						DccumentsFactory.updateDocumentType(document).then(function(result) {
                			data.close();
	                        $state.reload();
	                    }).catch(function(error) {
		                    data.alerts.push({ msg: $filter('translate')('global.errors.unknownUpdate') });
	                	});
					}
                }
				var openModal = function(){
					var documentTypes = $linq(GlobalDataFactory.documentTypes).where("x => x.id != "+vm.documentComponent.document.type.id +" && x.canChangeToThisType && x.canChangeToThisType == true").toArray();
					var modal = DccumentsFactory.getOpenNewDocumentModal(undefined, undefined, 'DOC', undefined, undefined, vm.documentComponent.document.archiveClassification, undefined, undefined, undefined, undefined, angular.copy(documentTypes));
		    		modal.annexaFormly.fields[0].fieldGroup[1].hideExpression = function(){return true;}
		    		modal.annexaFormly.fields[0].fieldGroup[2].hideExpression = function(){return true;}
		    		modal.annexaFormly.fields[0].fieldGroup[3].hideExpression = function(){return true;}
		    		modal.annexaFormly.fields[0].fieldGroup[4].hideExpression = function(){return true;}
		    		modal.annexaFormly.fields[0].fieldGroup[5].hideExpression = function(){return true;}
		    		modal.annexaFormly.fields[0].fieldGroup[6].hideExpression = function(){return true;}
					var fieldsFromCF = $linq(modal.annexaFormly.fields[0].fieldGroup).where(function(x){if(x && x.key && x.key.startsWith('cf_')){return true}else{return false}}).toArray();
					if(fieldsFromCF){
						_.forEach(fieldsFromCF, function(ffcf){
							ffcf.hideExpression = function ($viewValue, $modelValue, scope) {
		                        if(!ffcf.noEditable) {
									var splitkey = ffcf.key.split('_');
									if(splitkey && splitkey.length == 3){
		                            	return !(splitkey[1] == scope.model.typeSelect);
									}else{
										return false;
									}
		                        }else {
		                            return true;
		                        }
		                    }
						});
					}
 					var modal_body_key = modal.annexaFormly.fields[0].key;
					if(vm.documentComponent && vm.documentComponent.document && vm.documentComponent.document.customFields && vm.documentComponent.document.customFields.length > 0 && vm.documentComponent.document.type && vm.documentComponent.document.type.id){
						if(documentTypes && documentTypes.length > 0){
							_.forEach(vm.documentComponent.document.customFields, function (cfOld) {
								_.forEach(documentTypes, function (type) {
					                _.forEach(type.customFields, function(field) {
										if(cfOld && field && cfOld.customField && field.customField && cfOld.customField.id && cfOld.customField.id == field.customField.id ){
											if(cfOld.value){
												var valueJson = JSON.parse(cfOld.value)
												if(valueJson){
													var cfAux = angular.copy(cfOld);
													if(cfAux && cfAux.customField && cfAux.customField.backendType == 'DATETIME' && valueJson.value){
														cfAux.value = $filter('date')(new Date(valueJson.value), 'dd/MM/yyyy');
													}else{
														cfAux.value = valueJson.value;	
													}
													modal.annexaFormly.model[modal_body_key]['cf_' + type.id + '_' + field.customField.id] = CustomFieldFactory.calculateValueCustomField(cfAux, modal.annexaFormly.model[modal_body_key], ('cf_' + type.id + '_' + field.customField.id));	
												}
											}
 										}
					                });
								});
			            	 });
						}
	                }
		    		AnnexaFormlyFactory.showModal('modalEniDocumentNew', modal, saveModalUpdateThird, false, undefined);                 
				}
				if(vm.documentComponent && vm.documentComponent.document && vm.documentComponent.document.customFields && vm.documentComponent.document.customFields.length > 0){
					DialogsFactory.confirm('global.literals.changeTypeLostInformation', 'global.literals.changeTypeLostInformationBody').then(function (data) {
			        	openModal();
		            }).catch(function (data) {
			        });					
				}else{
					openModal();
				}
			}
			
			vm.printDocumentType = function(model){
                if(vm.languageColumn && vm.documentComponent && vm.documentComponent.document && vm.documentComponent.document.type && vm.documentComponent.document.type[vm.languageColumn]){
					return vm.documentComponent.document.type[vm.languageColumn];
				}else{
					return "";
				}
			}

 			this.$onInit = function() {
				if( vm.documentComponent && vm.documentComponent.document && vm.documentComponent.document.type && vm.documentComponent.document.type.id){
					var documentType = $linq(GlobalDataFactory.documentTypes).firstOrDefault(undefined, "x => x.id == "+vm.documentComponent.document.type.id);
					if(documentType && documentType.canChangeType){
						vm.canChangeDocumentType = true;
					}	
				}
           }
        }]
    })