angular
    .module('annexaApp')
    .component('annexaDocumentViewModesFileActions', {
        templateUrl: './components/doc/annexa-document-view-modes-file-actions/annexa-document-view-modes-file-actions.html',
        controller: ['$scope', 'DccumentsFactory', function ($scope, DccumentsFactory) {
            var vm = this;

            vm.actionsHtml = '';

            this.$onInit = function() {
                vm.actionsHtml = getActions();
            }

            $scope.$on('documentActionButtonExecuted', function(event, args) {
                if(args.button == 'complete' || args.button == 'sendToSign') {
                    vm.actionsHtml = getActions();
                }
            });

            $scope.$on('SendToSignModalFinished', function (event) {
                vm.actionsHtml = getActions();
            })
        }],
        bindings: {
            file: '='
        }
    })