/**
 * Created by osirvent on 24/03/2017.
 */
angular
    .module('annexaApp')
    .component('annexaDocumentViewModesSelector', {
        templateUrl: './components/doc/annexa-document-view-modes-selector/annexa-document-view-modes-selector.html',
        controller: ['Language', '$rootScope', function (Language, $rootScope) {
            var vm = this;

            vm.languageColumn = Language.getActiveColumn();

            vm.isViewModeActive = function(id) {
                return id == vm.active;
            };

            vm.changeViewMode = function(id) {
                vm.active = id;

                $rootScope.$broadcast('documentsViewModeChange', { id: id });
            };

        }],
        bindings: {
            active: '=',
            types: '=',
            showList: '=',
            showFolders: '='
        }
    });