/**
 * Created by osirvent on 26/04/2017.
 */
angular
    .module('annexaApp')
    .component('annexaDocumentRegisterEntriesBox', {
        templateUrl: './components/doc/annexa-document-register-entries-box/annexa-document-register-entries-box.html',
        controller: [function () {
            var vm = this;
        }],
        bindings: {
            registerEntries: '='
        }
    })