angular
    .module('annexaApp')
    .component('annexaScannerDocuments',{
        templateUrl: './components/doc/annexa-scanner-documents/annexa-scanner-documents.html',
        controller: ['$scope', 'DccumentsFactory', '$filter', function($scope, DccumentsFactory, $filter) {
            var vm = this;
            var completed = 0;

            vm.processState = 'global.literals.fromScannerUploadDocuments';

            var checkCompleted = function() {
                completed++;

                if(completed == vm.documents.length) {
                    vm.processState = 'global.literals.fromScannerUploadDocumentsCompleted';
                    angular.element('#fromScannerWizardModal .modal-footer button').removeClass('disabled');
                }
            }

            $scope.$on('fromScannerUploadDocuments', function (event, args) {
                vm.processState = 'global.literals.fromScannerUploadDocumentsStart';
                angular.element('#fromScannerWizardModal .wizard-buttons').hide();
                angular.element('#fromScannerWizardModal .modal-footer button').text($filter('translate')('global.literals.close'));
                angular.element('#fromScannerWizardModal .modal-footer button').addClass('disabled');

                _.forEach(vm.documents, function (doc, key) {
                    vm.documents[key].procState = 'uploading';

                    DccumentsFactory.createNewDocument(doc, doc.module)
                        .then(function(data) {
                            checkCompleted();

                            vm.documents[key].procState = 'complete';
                            vm.documents[key].docData = data;
                        }).catch(function(error) {
                            checkCompleted();

                            vm.documents[key].procState = 'error';
                            vm.documents[key].error = $filter('translate')(error.msg);
                    })
                });
            })
        }],
        bindings: {
            documents: '=',
            module: '='
        }
    })