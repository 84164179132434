/**
 * Created by osirvent on 25/04/2017.
 */
angular
    .module('annexaApp')
    .component('annexaDocumentInfoArea', {
        templateUrl: './components/doc/annexa-document-info-area/annexa-document-info-area.html',
        require: {
            documentComponent: '^^annexaDocument'
        },
        controller: [function () {
            var vm = this;
            vm.signProcess = undefined;
            this.$onInit = function() {
            	if(vm.documentComponent && vm.documentComponent.document && vm.documentComponent.document.signProcesses && vm.documentComponent.document.signProcesses.length > 0){
            		var sp = $linq(vm.documentComponent.document.signProcesses).where("x => x.signState == 'PENDING' || x.signState == 'IDLE'").orderBy("x => x.createdDate").toArray();
            		if(sp && sp.length > 0){
            			vm.signProcess = sp[0];
            		}else{
            			sp = $linq(vm.documentComponent.document.signProcesses).orderBy("x => x.createdDate").toArray()
            			if(sp && sp.length > 0){
                			vm.signProcess = sp[0];
                		}
            		}
            	}
            }
        }]
    })