/**
 * Created by osirvent on 26/04/2017.
 */
angular
    .module('annexaApp')
    .component('annexaDocumentDossierTransactionBox', {
        templateUrl: './components/doc/annexa-document-dossiers-transaction-box/annexa-document-dossier-transaction-box.html',
        controller: ['Language', 'DialogsFactory', '$rootScope', function (Language, DialogsFactory, $rootScope) {
            var vm = this;
            vm.langColumn = Language.getActiveColumn();
            
            vm.delete = function(index){
            	DialogsFactory.confirm('global.literals.delete', 'global.literals.deleteDossierBox')
                .then(function (data) {
                    if(vm.isEdit) {
                    	var removedId = vm.dossierTransactions[index].id;
                        vm.dossierTransactions.splice(index, 1);
                        $rootScope.$broadcast('annexaBoxDossierTransactionDelete', { removedId: removedId, origin: vm.origin});
                    }
                }).catch(function (data) {
                    //Empty
            });
            }
            
            this.$onInit = function() {
         	   vm.canEdit = ((vm.canEdit)?vm.canEdit:false);
            }
        }],
        bindings: {
            dossierTransactions: '=',
            isEdit: '=?',
            origin: '='
        }
    })