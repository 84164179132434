/**
 * Created by aarques on 12/11/20.
 */
angular
    .module('annexaApp')
    .component('annexaDocumentHighLevelInfo', {
        templateUrl: './components/doc/annexa-document-high-level-info/annexa-document-high-level-info.html',
        require: {
            documentComponent: '^^annexaDocument'
        },
        controller: ['DccumentsFactory', function(DccumentsFactory) {
            var vm = this;
            vm.showJobUploadDocumentInfo = false;
            
            this.$onInit = function() {
            	var jobUploadDocument = DccumentsFactory.pendingErrorJobDocument;

            	if(jobUploadDocument != null && jobUploadDocument.errorCounter > 10) {
            		vm.jobDocumentStatus = "warning";
            		vm.literalJobDocument = "global.documents.jobUploadDocumentsWithError";
            		vm.showJobUploadDocumentInfo = true;
            	} else if(jobUploadDocument != null && jobUploadDocument.errorCounter <= 10) {
            		vm.jobDocumentStatus = "info";
            		vm.literalJobDocument = "global.documents.jobUploadDocumentsPending";
            		vm.showJobUploadDocumentInfo = true;
            	} else {
                    return "";
            	}
            }

        }]
    })
