/**
 * Created by osirvent on 26/04/2017.
 */
angular
    .module('annexaApp')
    .component('annexaDocumentDossiersBox', {
        templateUrl: './components/doc/annexa-document-dossiers-box/annexa-document-dossiers-box.html',
        controller: [function () {
            var vm = this;
        }],
        bindings: {
            dossiers: '='
        }
    })